import classNames from 'clsx';
import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  BOTTOM_BANNER_ENABLED,
  FREESTAR_ENABLED,
} from 'src/common/constants/experiments/ads';
import { selectExperiment } from 'src/common/selectors/config';
import {
  getDisplayAdAttributes,
  gptSlotNames,
} from 'src/common/utils/ads/constants';
import {
  clearSearch,
  performSearch,
  searchResultsContentId,
  searchSuggestionsContentId,
} from '../../actions/search';
import {
  LIVE_DEBOUNCE_DELAY,
  LIVE_RESULTS_ENABLED,
  MINIMUM_CHARACTER_THRESHOLD,
} from '../../constants/experiments/search';
import { SEARCH_CAPITALIZED } from '../../constants/localizations/search';
import { disableLoadingState } from '../../constants/locationState';
import connectWithExperiments from '../../decorators/connectWithExperiments';
import connectWithFailureState from '../../decorators/connectWithFailureState';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectIsUserSubscribed } from '../../selectors/me';
import {
  selectHasSearchSuggestions,
  selectSearchResults,
} from '../../selectors/search';
import commonCss from '../../styles/common.module.scss';
import { isSmall, isXLarge, isXXLarge } from '../../utils/breakpoints';
import debounce from '../../utils/debounce';
import {
  escapeQuery,
  getSearchLocationDescriptor,
} from '../../utils/search/getSearchUrl';
import SeoHelmet from '../SeoHelmet';
import Title from '../Title';
import DisplayAd from '../ads/DisplayAd';
import ContainerItemsLoader from '../containerItems/ContainerItemsLoader';
import { createSearchPageTitle } from '../utils/pageTitles';
import SearchContent from './SearchContent';
import getSearchQueryObj from './getSearchQueryObj';
import css from './search.module.scss';

const partialPageTitle = 'Search results for ';
const defaultPageTitle = 'Search';

// Default minimum character length for a search query parameter, for live searching.
const defaultMinCharLength = 3;

// Default delay (ms) to impose between live search calls.
const defaultLiveDebounceDelay = 250;

function getPageTitle(term) {
  return term ? `${partialPageTitle}"${term}"` : defaultPageTitle;
}

export class Search extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    breakpoint: PropTypes.number.isRequired,
    routeProps: PropTypes.object.isRequired,
    hasDisplayAdBannerContainer: PropTypes.bool.isRequired,
    entry: PropTypes.object,
    searchTerm: PropTypes.string,
    actions: PropTypes.shape({
      clearSearch: PropTypes.func.isRequired,
      performSearch: PropTypes.func.isRequired,
    }),
    hasSearchSuggestions: PropTypes.bool.isRequired,
    isDiscord: PropTypes.bool.isRequired,
    [LIVE_RESULTS_ENABLED]: PropTypes.bool,
    [LIVE_DEBOUNCE_DELAY]: PropTypes.number,
    [MINIMUM_CHARACTER_THRESHOLD]: PropTypes.number,
    isFreestarEnabled: PropTypes.bool.isRequired,
    isBottomBannerEnabled: PropTypes.bool.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);

    this.cancelCurrentSearch = this.cancelCurrentSearch.bind(this);
    this.onSearchbarChange = this.onSearchbarChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.executeSearch = debounce(
      this.executeSearch.bind(this),
      props[LIVE_DEBOUNCE_DELAY] || defaultLiveDebounceDelay,
      { leading: false, trailing: true },
    );
  }

  componentWillUnmount() {
    const { hasSearchSuggestions, actions } = this.props;
    this.cancelCurrentSearch();
    if (hasSearchSuggestions) {
      actions.clearSearch(searchSuggestionsContentId);
    }
  }

  onSearchbarChange(event, value) {
    const {
      history,
      [LIVE_RESULTS_ENABLED]: liveResultsEnabled,
      [MINIMUM_CHARACTER_THRESHOLD]: minCharThreshold = defaultMinCharLength,
    } = this.props;

    if (!liveResultsEnabled) {
      return;
    }

    if (!value) {
      this.executeSearch(getSearchLocationDescriptor());
      if (this.props.hasSearchSuggestions) {
        this.props.actions.clearSearch(searchSuggestionsContentId);
      }
      return;
    }

    const query = escapeQuery(value);
    // Only begin processing if:
    // - there is a term present,
    // - the term meets the minimum character threshold
    // - the last character in the term is not a space
    if (
      query &&
      query.length >= minCharThreshold &&
      !/\s/.test(query.charAt(query.length - 1))
    ) {
      this.cancelCurrentSearch();

      const searchLocationDescriptor = {
        ...getSearchLocationDescriptor(query),
        state: {
          // Disable the loader for live searching, so the loader isn't intrusive while typing.
          [disableLoadingState]: true,
        },
      };
      if (query.length === minCharThreshold) {
        history.replace(searchLocationDescriptor);
      } else {
        this.executeSearch(searchLocationDescriptor);
      }
    }
  }

  onSubmit(latestQuery) {
    const { actions, location } = this.props;

    this.cancelCurrentSearch();

    const searchQueryObj = getSearchQueryObj(location);
    // Apply the latest search value entered when the query param value is not updated yet to the latest.
    if (latestQuery && latestQuery !== searchQueryObj.query) {
      searchQueryObj.query = latestQuery;
    }

    actions.performSearch(searchQueryObj);
  }

  executeSearch(location) {
    this.props.history.replace(location);
  }

  cancelCurrentSearch() {
    this.executeSearch.cancel();
  }

  render() {
    const { getLocalizedText } = this.context;
    const {
      entry,
      searchTerm,
      breakpoint,
      routeProps,
      hasDisplayAdBannerContainer,
      isDiscord,
      isFreestarEnabled,
      isBottomBannerEnabled,
    } = this.props;

    const pageTitle = getPageTitle(searchTerm);

    const shouldShowDisplayAd =
      ((isSmall(breakpoint) && !isXLarge(breakpoint)) ||
        isXXLarge(breakpoint)) &&
      !isBottomBannerEnabled;

    return (
      <div className={commonCss.contentContainer}>
        <SeoHelmet
          title={createSearchPageTitle(getLocalizedText, searchTerm)}
          description={pageTitle}
          keywords={[searchTerm]}
          meta={[{ name: 'robots', content: 'noindex' }]}
        />
        {shouldShowDisplayAd && (
          <DisplayAd
            className={classNames({
              [css.topBannerContainer]: hasDisplayAdBannerContainer,
            })}
            style={{ marginBottom: '45px' }}
            guideId={routeProps?.guideContext?.guideId}
            matchUrl={routeProps.matchUrl}
            {...getDisplayAdAttributes(
              gptSlotNames.browse_top,
              isFreestarEnabled,
            )}
          />
        )}
        <Title pageTitle={pageTitle} isHidden={isDiscord}>
          {getLocalizedText(SEARCH_CAPITALIZED)}
        </Title>
        <SearchContent
          searchTerm={searchTerm}
          onChange={this.onSearchbarChange}
          onSubmit={this.onSubmit}
        >
          {!isEmpty(entry) && !isEmpty(entry.containerItems) && (
            <div id="searchContainerItems" data-testid="searchContainerItems">
              <ContainerItemsLoader
                key="searchContainerItems"
                items={entry.containerItems}
                itemsStyles={entry?.itemsStyles || {}}
                isFetching={entry.isFetching}
                selectedContentId={searchResultsContentId}
                breakpoint={breakpoint}
                routeProps={routeProps}
              />
            </div>
          )}
        </SearchContent>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const entry = selectSearchResults(state);
  const hasSearchSuggestions = selectHasSearchSuggestions(state);

  // Be default, we want to lock in the display banner at the top to a fixed height, to prevent the
  // content from jumping continously when live search is enabled. However, for premium users, since
  // ads are disabled anyway, we should eliminate the fixed height as it's unnecessary space. This
  // flag will allow for that.
  // NOTE: this is probably a tactic we should do everywhere, as content seems to continuously jump
  // in a variety of other places when ads are requested based on other user actions.
  const hasDisplayAdBannerContainer = !selectIsUserSubscribed(state);

  return {
    entry,
    searchTerm: entry?.searchTerm,
    hasDisplayAdBannerContainer,
    hasSearchSuggestions,
    isFreestarEnabled: selectExperiment(state, FREESTAR_ENABLED),
    isBottomBannerEnabled: selectExperiment(state, BOTTOM_BANNER_ENABLED),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        clearSearch,
        performSearch,
      },
      dispatch,
    ),
  };
}

export default flow(
  connectWithFailureState(selectSearchResults),
  connect(mapStateToProps, mapDispatchToProps),
  connectWithExperiments([
    LIVE_RESULTS_ENABLED,
    MINIMUM_CHARACTER_THRESHOLD,
    LIVE_DEBOUNCE_DELAY,
  ]),
)(Search);
